
import { Component, Vue } from '@/lib/decorator';
import TitleReturn from '@/components/TitleReturn.vue';
import Markers from '@/components/Markers.vue';
import Loading from '@/components/Loading.vue';
import FormBlock from './_form.vue';
import { cancelMailing, copyMailing, deleteMailing, prepareMailing } from '@/api/mailings';
import MailingsModule from '@/store/settings/mailings/index';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import FormButton from '@/components/form/Button/index.vue';
import moment from 'moment';
import SettingsModule from '@/store/settings';
import { MailingsEntityModel } from '@/interfaces/models/mailings.interface';
import { Marker } from '@/interfaces/notifications.interface';

@Component({
  components: {
    TitleReturn,
    Markers,
    FormBlock,
    Loading,
    FormButton,
  },
})
export default class MailingsEdit extends Vue {
  isLoading = true;
  typeAction = 'create'; //create, draft, ready, awaiting, done
  modelClean: MailingsEntityModel | null = null;
  triggerSave = 0;
  triggerSendCreate = 0;
  scheduledDate: string | null = null;

  statuses: Record<string, string> = {
    create: 'Создание рассылки',
    draft: 'ЧЕРНОВИК',
    draft_edit: 'ИЗМЕНЕНИЕ РАССЫЛКИ',
    preparing: 'ПОДГОТОВКА',
    ready: 'СФОРМИРОВАНА',
    done: 'ОТПРАВЛЕНА',
  };

  canSave = true;
  canSend = true;

  get markers(): Marker[] {
    return MailingsModule.markers;
  }

  get titleEdit(): string {
    return MailingsModule.pageSettings.titleEdit;
  }

  get status(): string {
    const { statuses, typeAction } = this;

    return statuses[typeAction] || 'Рассылка';
  }

  returnBefore(): void {
    this.$router.push({ name: 'mailings' });
  }

  async copy(): Promise<void> {
    this.isLoading = true;

    try {
      const id = this.$route.params.id as string;
      const result = await copyMailing(id);

      if (!result.message) {
        this.returnBefore();

        ResponseHandlerModule.showNotify({ message: 'Рассылка скопирована', type: 'ok' });
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  }

  async prepare(): Promise<void> {
    if (moment(this.scheduledDate).unix() < moment().unix()) {
      ResponseHandlerModule.showNotify({ message: 'Дата отложенной отправки уже прошла', type: 'fail' });

      return;
    }

    try {
      const id = this.$route.params.id as string;
      const result = await prepareMailing(id);
      if (!result.message) {
        this.returnBefore();

        ResponseHandlerModule.showNotify({ message: 'Рассылка отправлена', type: 'ok' });
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }
    } catch (e) {
      console.error(e);
    }
  }

  async cancel(): Promise<void> {
    try {
      this.isLoading = true;

      const id = this.$route.params.id as string;
      const result = await cancelMailing(id);

      if (!result.message) {
        ResponseHandlerModule.showNotify({ message: 'Рассылка закрыта', type: 'ok' });

        this.returnBefore();
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }

      this.isLoading = false;
    } catch (e) {
      console.error(e);
    }
  }

  async remove(): Promise<void> {
    try {
      const id = this.$route.params.id as string;
      const result = await deleteMailing(id);

      if (!result.message) {
        ResponseHandlerModule.showNotify({ message: 'Рассылка удалена', type: 'ok' });

        this.returnBefore();
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }
    } catch (e) {
      console.error(e);
    }
  }

  async getById(): Promise<void> {
    const id = this.$route.params.id as string;

    if (id) {
      this.modelClean = await MailingsModule.getById(id);
      if (this.modelClean) {
        this.scheduledDate = this.modelClean.scheduledDate;
      }
    }
  }

  edit(): void {
    this.typeAction = 'draft_edit';
  }

  async cancelEdit(): Promise<void> {
    this.isLoading = true;

    await MailingsModule.resetModel();
    await MailingsModule.setInfo(this.modelClean as MailingsEntityModel);
    this.typeAction = 'draft';

    this.isLoading = false;
  }

  sendCreate(): void {
    this.triggerSendCreate++;
  }

  save(): void {
    this.triggerSave++;
  }

  changed(model: { scheduledDate: string }): void {
    this.typeAction = 'draft';
    this.scheduledDate = model.scheduledDate;
  }

  async mounted(): Promise<void> {
    SettingsModule.initTabsItems();
    await MailingsModule.initMarkers();
    await this.getById();

    if (this.modelClean) {
      this.typeAction = this.modelClean.status;
    }

    this.isLoading = false;
  }
}
