
import { Prop, Component, Watch } from '@/lib/decorator';
import { ref } from 'vue';

import Form from '@/layouts/Form';
import FormButton from '@/components/form/Button/index.vue';
import ModalBtnBottom from '@/layouts/ModalBtnBottom.vue';
import FormInput from '@/components/form/Input/index.vue';
import FormAutocomplete from '@/components/form/Autocomplete/index.vue';
import formValidate from '@/lib/formFactory/validate';

import { searchAddress } from '@/api/geo';

import ResponseHandlerModule from '@/store/modules/responseHandler';
import MailingsModule from '@/store/settings/mailings/index';

@Component({
  components: {
    FormButton,
    ModalBtnBottom,
    FormInput,
    FormAutocomplete,
  },
  emits: ['click'],
})
export default class MailingsPointsEdit extends Form {
  @Watch('selectedAddress')
  async addressStringChangedInModel(newValue: { address: string; latitude: number; longitude: number }) {
    if (newValue.address !== '') {
      this.addressesLoading = true;
      this.address = { id: newValue.address, value: newValue.address };
      this.addressesItems = [
        {
          id: newValue.address,
          value: newValue.address,
          latitude: newValue.latitude,
          longitude: newValue.longitude,
        },
      ];

      this.updateSelectedAddress({
        id: newValue.address,
        value: newValue.address,
        data: {
          latitude: newValue.latitude,
          longitude: newValue.longitude,
        },
      });
    }
  }

  @Prop() model!: any;

  form = ref(null);
  validate = formValidate(this.form);

  distance = this.model.distance ?? '';
  address: { id: string; value: string } = { id: null, value: this.model.address ?? '' } as any;
  addressesItems: {
    id: string;
    value: string;
    latitude: number;
    longitude: number;
  }[] = [];
  addressesLoading = false;

  coordinates: { lat?: number; lng?: number } = this.model.coordinates
    ? { lat: this.model.coordinates.latitude, lng: this.model.coordinates.longitude }
    : {};

  get selectedAddress() {
    return MailingsModule.selectedAddress;
  }

  prepareResultSearchAddress(addresses: { address: string; lat: number; long: number }[]) {
    return addresses.map((address: { address: string; lat: number; long: number }) => {
      return {
        id: address.address,
        value: address.address,
        latitude: address.lat,
        longitude: address.long,
      };
    });
  }

  update() {
    if (!this.address.value.trim().length) {
      return;
    }

    this.$emit('update', {
      address: this.address,
      distance: this.distance,
      coordinates: this.coordinates,
    });
  }

  async searchAddress(value: string) {
    try {
      this.addressesLoading = true;

      if (value.length > 2) {
        const result = await searchAddress(value);

        this.addressesItems = this.prepareResultSearchAddress(result);
      } else {
        this.addressesItems = [];
      }

      this.addressesLoading = false;
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.errors.fields, type: 'fail' });
    }
  }

  async updateSelectedAddress(selected: { id?: string; value: string; data?: { latitude: number; longitude: number } }) {
    if (!selected.value.trim().length) {
      return false;
    }

    if (selected.data) {
      this.coordinates = { lat: selected.data.latitude, lng: selected.data.longitude };
    }

    this.update();
  }

  async updateAddress(e: { target: { value: string } }) {
    await this.updateSelectedAddress({ value: e.target.value });
  }

  async save() {
    const valid = await this.validate.validate(true);

    if (!valid) {
      return;
    }

    this.$emit('save', {
      address: this.address.value,
      distance: this.distance,
      addressCoordinates: {
        latitude: this.coordinates.lat,
        longitude: this.coordinates.lng,
      },
    });

    this.$emit('click');

    ResponseHandlerModule.showNotify({ message: 'Точка сохранена', type: 'ok' });
  }
}
