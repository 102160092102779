
import { Prop, Component, Vue, Watch } from '@/lib/decorator';

import FormInput from '@/components/form/Input/index.vue';
import FormMultiSelect from '@/components/form/MultiSelect/index.vue';
import FormAutocomplete from '@/components/form/Autocomplete/index.vue';
import FormTimestampPicker from '@/components/form/TimePicker/TimestampPickerFilter.vue';
import Point from './Point/index.vue';
import { GuiTextField, GuiButton, GuiFormCheckbox } from '@library/gigant_ui';

import sendingTime from './sendingTime';
import { getRegionsAll } from '@/api/region';
import { getClients } from '@/api/client';
import { ref } from 'vue';
import formValidate from '@/lib/formFactory/validate';
import { getAllCompetenceList } from '@/api/competence';
import { getEmployeeRoleList, searchEmployee } from '@/api/employees';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import { addNewMailing, saveMailing, prepareMailing } from '@/api/mailings';
import MailingsModule from '@/store/settings/mailings';
import IconFont from '@/components/icons/IconFont.vue';

import { countSmsMessages, dateFormat } from '@/lib/Utils';
import { EXCEED_LENGTH_MESSAGE } from '@/lib/util/consts';
import moment from 'moment/moment.js';
import FormButton from '@/components/form/Button/index.vue';

import ModalBlock from '@/components/Modal.vue';
import { employeeStatusesList } from '@/lib/util/employeeStatuses';
import { strings } from '@/lib/stringConst';
import { EventBus } from '@/lib/event-bus';
import { Marker, TextFieldMaxLengthEvent } from '@/interfaces/notifications.interface';
import { MailingsCoordsModel, MailingsModel } from '@/interfaces/models/mailings.interface';
import { CatchFormResponse } from '@/interfaces/shared';
import { getServiceTypeAll } from '@/api/templates';
import { MultiSelectItem } from '@/interfaces/multiSelect.interface';

type CreateMailingError = CatchFormResponse<{ failedImportsCsvPath: string; mailingId: number }>;

@Component({
  components: {
    FormButton,
    FormInput,
    FormMultiSelect,
    FormAutocomplete,
    FormTimestampPicker,
    GuiTextField,
    GuiButton,
    IconFont,
    Point,
    ModalBlock,
    GuiFormCheckbox,
  },
  emits: ['can-save', 'updated', 'can-send'],
})
export default class NotificationsInfo extends Vue {
  @Prop() typeAction!: string;
  @Prop() triggerSave!: number;
  @Prop() triggerSendCreate!: number;

  @Watch('rolesModify')
  rolesValueChanged(newValue: Record<string, string>): void {
    if (!Object.keys(newValue).length) {
      this.excludeRoles = false;
    }
  }

  @Watch('statusesModify')
  statusesValueChanged(newValue: Record<string, string>): void {
    if (!Object.keys(newValue).length) {
      this.excludeStatuses = false;
    }
  }

  @Watch('competencesModify')
  competencesValueChanged(newValue: Record<string, string>): void {
    if (!Object.keys(newValue).length) {
      this.excludeCompetences = false;
    }
  }

  @Watch('clientsModify')
  clientsValueChanged(newValue: Record<string, string>): void {
    if (!Object.keys(newValue).length) {
      this.excludeClients = false;
    }
  }

  @Watch('regionsModify')
  regionsValueChanged(newValue: Record<string, string>): void {
    if (!Object.keys(newValue).length) {
      this.excludeRegions = false;
    }
  }

  @Watch('serviceTypesModify')
  serviceTypesValueChanged(newValue: Record<string, string>): void {
    if (!Object.keys(newValue).length) {
      this.excludeServiceTypes = false;
    }
  }

  clearListModalShown = false;
  importFileModalShown = false;

  form = ref(null);
  validate = formValidate(this.form);
  name = this.model.name ?? '';

  warningMessage = EXCEED_LENGTH_MESSAGE;

  individualMailing = this.model.individualMailing ?? 0;
  delayedDispatch = this.model.delayedDispatch ?? 0; // Отложенная отправка

  useTimezone = this.model.useTimezone ?? 0; // С учётом часового пояса
  scheduledDate = this.model.scheduledDate ?? sendingTime; // Запланированное время отправки

  // PUSH
  sendPush = this.model.sendPush ?? 0;
  pushText = this.model.pushText ?? '';
  notificationTitle = this.model.notificationTitle ?? '';
  notificationText = this.model.notificationText ?? '';

  // SMS
  sendSms = this.model.sendSms ?? 0;
  smsText = this.model.smsText ?? '';

  excludeRoles = this.model.excludeRoles ?? false;
  roles = this.model.roles ?? [];
  rolesItems: { id: string; value: string }[] = [];
  rolesModify = {};

  excludeStatuses = this.model.excludeStatuses ?? false;
  statuses = this.model.statuses ?? [];
  statusesItems: { id: string; value: string }[] = [];
  statusesModify = {};

  excludeCompetences = this.model.excludeCompetences ?? false;
  competences = this.model.competences ?? [];
  competencesItems: { id: string; value: string }[] = [];
  competencesModify = {};

  excludeClients = this.model.excludeClients ?? false;
  clients = this.model.clients ?? [];
  clientsItems: { id: string; value: string }[] = [];
  clientsModify = {};

  excludeRegions = this.model.excludeRegions ?? false;
  regions = this.model.regions ?? [];
  regionsItems: { id: string; value: string }[] = [];
  regionsModify = {};

  excludeServiceTypes = this.model.excludeServiceTypes ?? false;
  serviceTypes = this.model.serviceTypes ?? [];
  serviceTypeItems: MultiSelectItem[] = [];
  serviceTypesModify = {};

  lat = this.model.coordinates?.latitude ?? null;
  long = this.model.coordinates?.longitude ?? null;
  distance = this.model.distance ?? '1';
  address = this.model.address ?? '';

  employee = { id: null, value: '' };
  employees = this.model.employees ?? [];
  employeesLoading = false;
  employeesItems: { id: string; value: string }[] = [];
  showEmployeesAll = false;
  employeesFile = this.model.employeesFile ?? { name: '' };

  errorContenteditable = {
    pushText: false,
    smsText: false,
    notificationText: false,
  };

  errorsMaxLength = {
    possiblyExceeds: {} as Record<string, boolean>,
    exactlyExceeds: {} as Record<string, boolean>,
  };

  get withError(): boolean {
    return Object.values(this.errorsMaxLength.exactlyExceeds).some(Boolean);
  }

  get withWarning(): boolean {
    return Object.values(this.errorsMaxLength.possiblyExceeds).some(Boolean);
  }

  get smsCount(): number {
    return countSmsMessages(this.smsText);
  }

  get markers(): Marker[] {
    return MailingsModule.markers;
  }

  get currentDate(): string {
    return moment().format('DD/MM/YYYY HH:mm');
  }

  get targetGroup(): boolean {
    return Boolean(
      this.individualMailing ||
        this.roles.length ||
        this.statuses.length ||
        this.clients.length ||
        this.address.length ||
        this.regions.length ||
        this.serviceTypes.length ||
        this.competences.length ||
        this.employees.length
    );
  }

  get readonly(): boolean {
    return this.typeAction !== 'create' && this.typeAction !== 'draft_edit';
  }

  get typeEdit(): boolean {
    return this.typeAction === 'create' || this.typeAction === 'draft_edit';
  }

  get canSave(): boolean {
    return Boolean(this.name.length);
  }

  get canSend(): boolean {
    return Boolean(
      // название рассылки не пусток
      this.name.length &&
        // хотя бы одна из галочек стоит
        (this.sendSms || this.sendPush) &&
        // если выбран пуш - то заполнены все поля
        (!this.sendPush || (this.sendPush && this.pushText && this.notificationTitle && this.notificationText)) &&
        // если выбрано смс - то заполнены все поля
        (!this.sendSms || (this.sendSms && this.smsText))
    );
  }

  get coords(): MailingsCoordsModel {
    return {
      distance: this.distance,
      address: this.address ?? '',
      coordinates: {
        latitude: this.lat as number,
        longitude: this.long as number,
      },
    };
  }

  get textFieldsData(): Record<string, string | number | null> {
    const data: Record<string, string | number | null> = {
      name: this.name,
      delayedDispatch: this.delayedDispatch ? 1 : 0,
      sendPush: this.sendPush ? 1 : 0,
      sendSms: this.sendSms ? 1 : 0,
      individualMailing: this.individualMailing ? 1 : 0,
    };

    if (this.sendPush) {
      data.pushText = this.pushText;

      data.sendNotification = this.sendPush ? 1 : 0;
      data.notificationTitle = this.notificationTitle;
      data.notificationText = this.notificationText;
    }

    if (this.sendSms) {
      data.smsText = this.smsText;
    }

    if (this.delayedDispatch) {
      data.useTimezone = this.useTimezone ? 1 : 0;

      const date = String(this.scheduledDate.date).split('/');

      data.scheduledDate = `${date[2]}-${date[1]}-${date[0]} ${this.scheduledDate.time}:00`;
    }

    if (!this.individualMailing) {
      if (this.address) {
        data.distance = this.distance;
        data.address = this.address;
        data.lat = this.lat;
        data.long = this.long;
      }

      data.excludeRoles = this.excludeRoles ? 1 : 0;
      data.excludeStatuses = this.excludeStatuses ? 1 : 0;
      data.excludeCompetences = this.excludeCompetences ? 1 : 0;
      data.excludeClients = this.excludeClients ? 1 : 0;
      data.excludeRegions = this.excludeRegions ? 1 : 0;
      data.excludeServiceTypes = this.excludeServiceTypes ? 1 : 0;
    }

    return {
      ...data,
      ...(!this.individualMailing && this.rolesModify ? this.rolesModify : {}),
      ...(!this.individualMailing && this.statusesModify ? this.statusesModify : {}),
      ...(!this.individualMailing && this.competencesModify ? this.competencesModify : {}),
      ...(!this.individualMailing && this.clientsModify ? this.clientsModify : {}),
      ...(!this.individualMailing && this.regionsModify ? this.regionsModify : {}),
      ...(!this.individualMailing && this.serviceTypesModify ? this.serviceTypesModify : {}),
      ...(this.individualMailing && this.employeesFile.name.length
        ? {
            employeesFile: this.employeesFile,
          }
        : this.prepareModelValue(this.employees, 'employees')),
    } as Record<string, string | number | null>;
  }

  get model(): MailingsModel {
    return MailingsModule.model;
  }

  @Watch('triggerSave')
  async onChangeTriggerSave(): Promise<void> {
    await this.save();
  }

  @Watch('triggerSendCreate')
  async onChangeTriggerSendCreate(): Promise<void> {
    await this.sendCreate();
  }

  @Watch('readonly')
  onChangeReadonly(): void {
    this.showEmployeesAll = this.readonly;
  }

  @Watch('canSave')
  onChangeCanSave(value: boolean): void {
    this.$emit('can-save', value);
  }

  @Watch('canSend')
  onChangeCanSend(value: boolean): void {
    this.$emit('can-send', value);
  }

  openMarkdownModal(): void {
    EventBus.$emit('showModal', { name: 'markdown', flag: true });
  }

  toDateFormat(value: string): string {
    return dateFormat(value, { from: '', to: 'DD/MM/YY HH:mm' });
  }

  validateMaxLength(value: TextFieldMaxLengthEvent): void {
    this.errorsMaxLength.exactlyExceeds[value.name] = value.rawLength > value.maxLength;
    this.errorsMaxLength.possiblyExceeds[value.name] = value.estimatedMaxLength > value.maxLength;
  }

  deleteEmployee(id: string): void {
    const result = this.employees.findIndex((item: { id: string }) => {
      return item.id === id;
    });

    this.employees.splice(result, 1);
  }

  prepareModelValue(arr: unknown[], name: string): Record<string, string> {
    const result: Record<string, string> = {};

    if (Array.isArray(arr)) {
      arr.forEach((item: { id: string }, index: number) => {
        result[`${name}[${index + 1}]`] = item.id;
      });
    }

    return result;
  }

  updateSendingTime(value: { date: { day: string; month: string; year: string }; hour: string; minute: string }): void {
    this.scheduledDate.date = `${value.date.day}/${value.date.month}/${value.date.year}`;
    this.scheduledDate.time = `${value.hour}:${value.minute}`;
  }

  async getRoles(): Promise<void> {
    const result = await getEmployeeRoleList();
    this.rolesItems = result.map((role: { type: string; humanType: string }) => {
      return {
        id: role.type,
        value: role.humanType,
        name: role.humanType,
      };
    });
  }

  async getStatuses(): Promise<void> {
    this.statusesItems = employeeStatusesList.map((status: { id: string; name: string }) => {
      return {
        id: status.id,
        value: status.name,
        name: status.name,
      };
    });
  }

  async getCompetences(): Promise<void> {
    const result = await getAllCompetenceList();
    this.competencesItems = result.map((competence: { id: string; name: string }) => {
      return {
        id: competence.id,
        value: competence.name,
        name: competence.name,
      };
    });
  }

  async getClients(): Promise<void> {
    try {
      const result = await getClients();
      this.clientsItems = result.clients.map((client: { id: string; name: string }) => {
        return {
          id: client.id,
          value: client.name,
          name: client.name,
        };
      });
    } catch (error) {
      this.clientsItems = [];
    }
  }

  async getRegions(): Promise<void> {
    const result = await getRegionsAll();
    this.regionsItems = result.map((region: { id: string; name: string }) => {
      return {
        id: region.id,
        value: region.name,
        name: region.name,
      };
    });
  }

  async getServiceTypes(): Promise<void> {
    const result = await getServiceTypeAll();
    this.serviceTypeItems = result.map((serviceType) => {
      return {
        id: serviceType.id,
        value: serviceType.name,
        name: serviceType.name,
        checked: false,
      };
    });
  }

  prepareResultSearchEmployee(employees: { id: string; fullName: string }[]): { id: string; value: string }[] {
    return employees.map((item: { id: string; fullName: string }) => {
      return {
        id: item.id,
        value: item.fullName,
        name: item.fullName,
      };
    });
  }

  async searchEmployee(value: string): Promise<void> {
    try {
      if (value.length < 3) {
        return;
      }

      this.employeesLoading = true;
      if (value.length > 2) {
        const result = await searchEmployee(value);
        this.employeesItems = await this.prepareResultSearchEmployee(result);
      } else {
        this.employeesItems = [];
      }

      this.employeesLoading = false;
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message, type: 'fail' });
    }
  }

  updateSelectedEmployee(value: { id: string; value: string; data: { id: string; value: string } }): void {
    const search = this.employees.find((item: { id: string }) => {
      return item.id === value.id;
    });

    if (!search && value.data) {
      this.employees = [value.data, ...this.employees];
    }

    this.employee = { id: null, value: '' };
    this.employeesItems = [];
    this.deleteEmployeesFile();
  }

  returnBefore(): void {
    this.$router.push({ name: 'mailings' });
  }

  updatePoint(value: {
    address: string;
    distance: number;
    addressCoordinates: { latitude: number; longitude: number };
  }): void {
    this.lat = value.addressCoordinates.latitude;
    this.long = value.addressCoordinates.longitude;
    this.distance = value.distance;
    this.address = value.address;
  }

  validateContenteditable(): boolean {
    let result = true;

    if (this.sendPush && !this.pushText.length) {
      this.errorContenteditable.pushText = true;

      ResponseHandlerModule.showNotify({ message: 'Заполните текст push', type: 'fail' });

      result = false;
    }

    if (this.sendPush && !this.notificationText.length) {
      this.errorContenteditable.notificationText = true;

      ResponseHandlerModule.showNotify({ message: 'Заполните текст уведомлений', type: 'fail' });

      result = false;
    }

    if (this.sendSms && !this.smsText.length) {
      this.errorContenteditable.smsText = true;

      ResponseHandlerModule.showNotify({ message: 'Заполните текст sms сообщение', type: 'fail' });

      result = false;
    }

    return result;
  }

  downloadFailedImports(path: string): void {
    const downloadLink = document.createElement('a');
    downloadLink.href = path;
    downloadLink.target = '_blank';
    downloadLink.style.display = 'none';
    document.body.append(downloadLink);
    downloadLink.click();
    setTimeout(() => {
      downloadLink.remove();
    });
  }

  async add(): Promise<number | null> {
    try {
      const result = await addNewMailing(this.textFieldsData);

      let successMessage = 'Рассылка создана';
      if (this.withWarning) {
        successMessage += `: ${this.warningMessage.toLowerCase()}`;
      }

      if (!result.message) {
        ResponseHandlerModule.showNotify({ message: successMessage, type: 'ok' });
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }

      return result.result.mailing.id;
    } catch (error) {
      const errorResponse = (error as CreateMailingError)?.response?.data;
      let notifyMessage = strings.UNKNOWN_ERROR;

      if (typeof errorResponse?.result?.failedImportsCsvPath !== 'undefined') {
        //
        // при импорте были ошибки - скачиваем файл с ошибками
        //
        ResponseHandlerModule.showNotify({ message: 'Рассылка создана', type: 'ok' });
        this.downloadFailedImports(errorResponse.result.failedImportsCsvPath);

        return errorResponse.result.mailingId;
      } else if (typeof errorResponse?.errors?.fields === 'object') {
        notifyMessage = Object.values(errorResponse.errors.fields).join('; ');
      }

      ResponseHandlerModule.showNotify({ message: notifyMessage, type: 'fail' });

      return null;
    }
  }

  async edit(): Promise<void> {
    try {
      const id = this.$route.params.id as string;

      const result = await saveMailing(id, this.textFieldsData);

      if (!result.message) {
        let notifyMessage = 'Изменения сохранены';
        if (this.withWarning) {
          notifyMessage += `: ${this.warningMessage.toLowerCase()}`;
        }

        ResponseHandlerModule.showNotify({ message: notifyMessage, type: 'ok' });
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }

      this.$emit('updated', {
        scheduledDate: (this.textFieldsData as Record<string, string | number | null>).scheduledDate,
      });
    } catch (error) {
      if (typeof error.response.data.result.failedImportsCsvPath !== 'undefined') {
        //
        // при импорте были ошибки - скачиваем файл с ошибками
        //
        ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
        this.downloadFailedImports(error.response.data.result.failedImportsCsvPath);

        this.$emit('updated', {
          scheduledDate: (this.textFieldsData as Record<string, string | number | null>).scheduledDate,
        });
      }

      console.error(error.response);
    }
  }

  async prepare(id: string): Promise<void> {
    try {
      const result = await prepareMailing(id);
      if (!result.message) {
        ResponseHandlerModule.showNotify({ message: 'Рассылка отправлена', type: 'ok' });
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }
    } catch (e) {
      console.error(e);
    }
  }

  async sendCreate(): Promise<void> {
    const valid = await this.valid();

    if (!valid) {
      return;
    }

    const id = await this.add();

    await this.prepare((id as number).toString());

    this.$router.push({ name: 'mailings' });
  }

  async valid(): Promise<boolean> {
    const valid = await this.validate.validate(true);

    const validContenteditable = await this.validateContenteditable();

    if (!valid) {
      return false;
    }

    if (!validContenteditable) {
      return false;
    }

    if (this.individualMailing && !this.employees.length && !this.employeesFile.name.length) {
      ResponseHandlerModule.showNotify({ message: 'Не выбраны адресаты', type: 'fail' });

      return false;
    }

    if (this.withError) {
      ResponseHandlerModule.showNotify({ message: 'Превышено допустимое количество символов', type: 'fail' });

      return false;
    }

    return true;
  }

  async save(): Promise<void> {
    const valid = await this.valid();

    if (!valid) {
      return;
    }

    if (this.typeAction === 'create') {
      const id = await this.add();

      if (id) {
        this.returnBefore();
      }
    }

    if (this.typeAction === 'draft_edit') {
      await this.edit();
    }
  }

  clearRecipientsList(): void {
    this.clearListModalShown = false;
    this.employees = [];
  }

  employeesFileChanged(e: { target: { files: { name: string }[] } }): void {
    this.employeesFile = e.target.files[0];
    this.importFileModalShown = false;
    this.employees = [];
  }

  deleteEmployeesFile(): void {
    this.employeesFile = { name: '' };

    if (this.$refs.fileInput) {
      (this.$refs.fileInput as HTMLInputElement).value = '';
    }
  }

  unsetContenteditableVisit(): void {
    document.querySelectorAll('.gui-textarea').forEach((contenteditable: Element) => {
      contenteditable.removeAttribute('data-visit');
    });
  }

  checkShowWarningIcon(rawLength: number, estimatedMaxLength: number, maxLength: number): boolean {
    return rawLength <= maxLength && estimatedMaxLength > maxLength;
  }

  async created(): Promise<void> {
    this.$emit('can-send', this.canSend);
    this.$emit('can-save', this.canSave);

    if (this.readonly) {
      this.showEmployeesAll = true;
    }

    await this.getRoles();
    await this.getStatuses();
    await this.getClients();
    await this.getCompetences();
    await this.getRegions();
    await this.getServiceTypes();
  }
}
