
import { Component, Prop, Vue } from '@/lib/decorator';
import { Marker } from '@/interfaces/notifications.interface';

@Component({})
export default class NotificationsMarkers extends Vue {
  @Prop() markers!: Marker[];

  focusContentEditable(): boolean {
    const visit = document.querySelector('.gui-textarea__textarea[data-visit="true"]') as HTMLElement;

    if (visit) {
      visit.focus();

      return true;
    }

    return false;
  }

  pasteValue({ description, marker }: Marker): void {
    try {
      const getSelection: Selection | null = window.getSelection();
      const range = (getSelection as Selection).getRangeAt(0);
      const insertion = document.createDocumentFragment();
      const markerTag = document.createElement('span');
      const emptyTag = document.createElement('i');
      const spaceTag = document.createElement('span');

      markerTag.classList.add('marker');
      markerTag.dataset.raw = marker.replace(/\{|\}/g, '');
      markerTag.innerHTML = description;
      emptyTag.innerHTML = '&zwnj;';
      spaceTag.innerHTML = '&nbsp;';

      if (markerTag) {
        insertion.appendChild(markerTag);
        insertion.appendChild(emptyTag);
        insertion.appendChild(spaceTag);
      }

      range.deleteContents();
      range.insertNode(insertion);
      (getSelection as Selection).collapseToEnd();
      document.activeElement?.dispatchEvent(new Event('input'));
    } catch (error) {
      console.error(error);
    }
  }

  setCurrentMarker(marker: Marker): void {
    if (this.focusContentEditable()) {
      this.pasteValue(marker);
    }
  }
}
