
import { Prop, Component, Vue } from '@/lib/decorator';
import ModalBlock from '@/components/Modal.vue';

import EditPoint from './_edit.vue';
import FormInput from '@/components/form/Input/index.vue';

import YandexMap from '@/lib/map/yandexMap';
import mapEntity from '@/lib/formFactory/mapEntity';

import MailingsModule from '@/store/settings/mailings/index';
import { MailingsCoordsModel } from '@/interfaces/models/mailings.interface';
import { coordinates } from '@/lib/formFactory/map.interface';

const {
  defaultValue: { default: defaultCoordinates },
} = new mapEntity('addressCoordinates');

let YANDEX_MAP: YandexMap = Object.create(null);

@Component({
  components: {
    ModalBlock,
    EditPoint,
    FormInput,
  },
})
export default class MailingsPointsEdit extends Vue {
  @Prop() model!: MailingsCoordsModel;

  coordinates: coordinates = this.model.address ? this.model.coordinates : defaultCoordinates;
  point = this.model && this.model.address ? this.model.address : '';

  showMap = false;
  isInitMap = false;

  async initMap(): Promise<void> {
    const mapWrapper = document.getElementById('map');

    if (!this.coordinates) {
      return;
    }

    if (!mapWrapper) {
      setTimeout(() => {
        this.initMap();
      }, 300);

      return;
    }

    YANDEX_MAP = new YandexMap();

    YANDEX_MAP.initMap(this.coordinates, 'map');

    if (this.coordinates.latitude && this.coordinates.longitude) {
      YANDEX_MAP.addPlaceMarker(this.coordinates);
    }

    YANDEX_MAP.addEventsClick();

    this.updateDistance(
      { lat: this.coordinates.latitude, lng: this.coordinates.longitude },
      this.model && this.model.distance ? this.model.distance : 0,
      true
    );
  }

  updateDistance(point: { lat: number; lng: number }, distance: number, init?: boolean): void {
    if (!init) {
      YANDEX_MAP.clearCirclesByPoints();
    }

    YANDEX_MAP.createCirclesByPoints(
      {
        0: {
          addressCoordinates: { latitude: point.lat, longitude: point.lng },
          distance,
        },
      },
      true
    );
  }

  updatePoint(point: { lat: number; lng: number }, address: string, distance: number): void {
    this.updateDistance(point, distance);

    YANDEX_MAP.updatePlaceMarker([point.lat, point.lng]);
    YANDEX_MAP.entity.setCenter([point.lat, point.lng]);
    YANDEX_MAP.setPlaceMarkAddress(address);
  }

  updateAddress(value: {
    address: { id: string; value: string };
    distance: number;
    coordinates: { lat: number; lng: number };
  }): void {
    this.updatePoint(value.coordinates, value.address.value, value.distance ?? 0);
  }

  savePoint(value: {
    address: string;
    addressCoordinates: { latitude: number; longitude: number };
    distance: number;
  }): void {
    this.point = value.address;

    this.$emit('update', value);
  }

  updatePointCoordinates(event: Event): void {
    MailingsModule.updateSelectedAddress({
      latitude: (event as CustomEvent).detail[0],
      longitude: (event as CustomEvent).detail[1],
    });
  }

  async mounted(): Promise<void> {
    await this.initMap();

    this.isInitMap = true;

    (document.querySelector('.default-layout__menu') as HTMLElement).style.zIndex = '1';
  }

  destroyed(): void {
    (document.querySelector('.default-layout__menu') as HTMLElement).style.zIndex = '9999';
  }

  created(): void {
    document.body.addEventListener('yandexMapClick', this.updatePointCoordinates);
  }

  updated(): void {
    document.body.addEventListener('yandexMapClick', this.updatePointCoordinates);
  }

  unmounted(): void {
    document.body.addEventListener('yandexMapClick', this.updatePointCoordinates);
  }
}
